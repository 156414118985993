







































































































import { defineComponent } from '@vue/composition-api';

import HomePageParagraph from './typography/HomePageParagraph.vue';
import HomePageSubtitle from './typography/HomePageSubtitle.vue';
import HomePageLink from './typography/HomePageLink.vue';
import TheHeader from './components/TheHeader';
import TheFooter from './components/TheFooter';
import { mobileDevice } from '@/utils/userAgentHelper';

export default defineComponent({
  name: 'HomePage',
  components: {
    StressMap: () => {
      const isMobile = mobileDevice();
      if (isMobile) {
        return import('@/pages/HomePage/components/StressMapPreview');
      } else {
        return import('@/pages/HomePage/components/StressMapWrapper');
      }
    },
    TheHeader,
    TheFooter,
    HomePageParagraph,
    HomePageSubtitle,
    HomePageLink,
  },
});
