











import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'BurgerMenu',
  props: {
    active: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
});
