






import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'DropdownMenuHeader',
  props: {
    tag: {
      type: String as PropType<string>,
      required: false,
      default: 'a',
    },
  },
});
