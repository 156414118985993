





































import { defineComponent } from '@vue/composition-api';

import urlsConfig from '@/pages/HomePage/urlsConfig';

export default defineComponent({
  name: 'TheFooter',
  setup() {
    const menu = urlsConfig.menu;
    const donateLink = menu[0].links.find((el) => el.title.toLowerCase().startsWith('donate'));

    return { menu, donateLink };
  },
});
