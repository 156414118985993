
























































































































import { defineComponent, ref, watch } from '@vue/composition-api';
import { ChevronDownIcon } from 'vue-feather-icons';

import DropdownMenuHeader from '@/pages/HomePage/typography/DropdownMenuHeader.vue';
import DropdownMenuLink from '@/pages/HomePage/typography/DropdownMenuLink.vue';
import HeaderLink from '@/pages/HomePage/typography/HeaderLink.vue';
import BurgerMenu from '@/pages/HomePage/components/BurgerMenu.vue';

import urlsConfig from '@/pages/HomePage/urlsConfig';

export default defineComponent({
  name: 'TheHeader',
  components: {
    ChevronDownIcon,
    DropdownMenuHeader,
    DropdownMenuLink,
    HeaderLink,
    BurgerMenu,
  },
  setup() {
    const isOpen = ref(false);

    const menuRef = ref();
    const buttonRef = ref();
    const burgerRef = ref();

    const pvHref = urlsConfig.pvHref;
    const menu = urlsConfig.menu;

    watch(menuRef, (element, oldElement, onInvalidate) => {
      if (!element) return;

      const handleClickOutside = (event: any) => {
        isOpen.value =
          element.contains(event.target) ||
          buttonRef.value.$el.contains(event.target) ||
          burgerRef.value.$el.contains(event.target);
      };

      document.addEventListener('click', handleClickOutside);

      onInvalidate(() => {
        document.removeEventListener('click', handleClickOutside);
      });
    });

    const toggleMenu = () => {
      isOpen.value = !isOpen.value;
    };

    return { menu, pvHref, isOpen, toggleMenu, menuRef, buttonRef, burgerRef };
  },
});
