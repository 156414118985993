const pvHref = 'https://plantvillage.psu.edu';

const menu = [
  {
    title: 'About',
    href: '/',
    links: [
      {
        title: 'Projects',
        href: '/projects',
      },
      {
        title: 'Team',
        href: '/team',
      },
      {
        title: 'Blog',
        href: '/blogposts',
      },
      {
        title: 'Donate',
        href: '/donate',
      },
      {
        title: 'Contact us',
        href: 'mailto:mayor@plantvillage.psu.edu',
      },
    ],
  },
  {
    title: 'For Farmers',
    links: [
      {
        title: 'Crops',
        href: '/plants',
      },
      {
        title: 'Pests & Diseases',
        href: '/diseases',
      },
      {
        title: 'Topics',
        href: '/topics',
      },
      {
        title: 'Q & A',
        href: '/posts',
      },
      {
        title: 'Video Library',
        href: '/crops',
      },
    ],
  },
  {
    title: 'For Researchers',
    links: [
      {
        title: 'Stress Map',
        href: 'https://stressmap.plantvillage.psu.edu',
      },
      {
        title: 'Locust Map',
        href: '/panel/analytics/locust_surveys',
      },
    ],
  },
];

const buildHref = (item: { href?: string }) => {
  if (item.href && item.href.startsWith('/')) {
    return `${pvHref}${item.href}`;
  }
  return item.href || '';
};

menu.forEach((group) => {
  group.href = buildHref(group);
  group.links.forEach((link) => {
    link.href = buildHref(link);
  });
});

export default {
  menu,
  pvHref,
};
